<template>
  <div>
    <b-row>
      <b-col cols="12" sm="6" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-calendar-plus-o bg-danger p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-danger mb-0 mt-2">{{ stat.newOrders }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">{{ $t('newOrders') }}</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-clipboard bg-danger p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-danger mb-0 mt-2">{{ stat.dailyOrders }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">{{ $t('dailyOrders') }}</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-user bg-success p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-success mb-0 mt-2">{{ stat.users }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">{{ $t('users') }}</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-user-plus bg-success p-3 font-2xl mr-3 float-left"></i>
            <div class="h5 text-success mb-0 mt-2">{{ stat.newUsers }}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">{{ $t('newUsers') }}</div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {APIService} from "@/services/api";

export default {
  name: 'dashboard',
  components: {

  },
  data: function () {
    return {
      loaded: false,
      stat: {
        dailyOrders: 'N/A',
        newOrders: 'N/A',
        users: 'N/A',
        newUsers: 'N/A',
      }
    }
  },
  async beforeMount() {
    try {
      this.stat = await APIService.get('stat/general');
    } catch (e) {
      console.error(e.toString());
    }
    this.loaded = true;
  },
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
